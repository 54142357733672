'use client'

import { LightBulbIcon } from '@heroicons/react/24/outline'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useMemo } from 'react'

import avaIcon from '@/public/images/ava-icon.png'

import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'

import { generatePrompts } from '../Chat/chat.utils'

interface Card {
  text: string
  onClick: () => void
}

interface AvaContentProps {
  onCardSelected: (cardContent: string) => void
}

const PromptCard = (card: Card) => (
  <div
    onClick={card.onClick}
    className="flex flex-col items-center p-4 border rounded-2xl shadow-lg bg-white cursor-pointer"
  >
    <div className="w-8 h-8 mb-2">
      <LightBulbIcon className="h-7 w-7" />
    </div>
    <p>{card.text}</p>
  </div>
)

const AvaInitialState = ({ onCardSelected }: AvaContentProps) => {
  const t = useTranslations('AskAva')
  const tStudent = useTranslations('AVAStudentPrompts')
  const tParent = useTranslations('AVAParentPrompts')
  const tCounselor = useTranslations('AVACounselorPrompts')
  const avaIntro = t('avaIntro')

  const { data } = useSession()
  const generatedCards = useMemo(() => {
    if (data && data.user && data.user.role) {
      const { role } = data.user
      switch (role) {
        case UserRoles.COUNSELOR:
          return generatePrompts(tCounselor)
        case UserRoles.STUDENT:
          return generatePrompts(tStudent)
        case UserRoles.FAMILY:
          return generatePrompts(tParent)
      }
    }
    return []
  }, [data, tCounselor, tParent, tStudent])

  const handleCardClick = (text: string) => {
    onCardSelected(text)
  }

  return (
    <div id="ava-sidebar-content" className="p-2 md:p-4">
      <div className="flex items-center space-x-2">
        <div className="relative w-[28px] h-[45px]">
          <Image
            src={avaIcon}
            alt="Ava Icon"
            fill
            style={{ objectFit: 'contain' }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vwvw, 100vw"
          />
        </div>
        <h1 className="text-xl font-bold text-gray-900">{t('askAva')}</h1>
      </div>
      <div className="text-gray-900 mt-4 md:mt-8">{avaIntro}</div>
      {/* Prompt cards */}
      <div className="text-gray-900 mt-3 md:mt-6">
        <p>{t('suggestedPromtsIntro')}</p>
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            {generatedCards.map((card, index) => (
              <PromptCard key={index} text={card.text} onClick={() => handleCardClick(card.text)} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AvaInitialState
