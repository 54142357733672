'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Spinner } from '@material-tailwind/react'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useCallback, useRef, useState } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { z } from 'zod'

import { useChatbot } from '@/providers/ChatbotProvider'

import { generateCheckboxQuestions, generateTextQuestions } from '../Chat/chat.utils'
import { useClientConfig } from '../Chat/useConfig'
import CheckboxGroup from './RecLetterComponents/CheckboxGroup'
import TextQuestionComponent from './RecLetterComponents/TextQuestion'

const RecLetterSchema = z.object({
  formattingHints: z.string(),
  toneHints: z.string(),
  length: z.string(),
  gender: z.string(),
  distinctive_qualities: z.string(),
  academic_interests: z.string(),
  activities_and_interests: z.string(),
  personal_history_and_family_context: z.string(),
  summary: z.string(),
})

type RecLetterSchemaType = z.infer<typeof RecLetterSchema>

const RecLetterState = () => {
  const t = useTranslations('CounselorRecLetter')
  const { chatAPI } = useClientConfig()

  const [isSubmittingState, setIsSubmittingState] = useState(false)
  const { data: session, update } = useSession()
  const { register, setValue, handleSubmit, watch } = useForm<RecLetterSchemaType>({
    resolver: zodResolver(RecLetterSchema),
    defaultValues: session?.user.counselorForm ? { ...JSON.parse(session?.user.counselorForm) } : {},
  })

  const [errors, setErrors] = useState<FieldErrors<RecLetterSchemaType>>({})

  const textQuestions = generateTextQuestions(t)

  const checkboxGroups = generateCheckboxQuestions(t)

  const { setBreadcrumbs, breadcrumbs, selectedBreadcrumbIndex, setRecLetterResponse: setResponse } = useChatbot()

  const submitForm = useCallback(
    (jsonObject: any) => {
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')
      headers.append('Authorization', `Bearer ${session?.token}`)
      const url = chatAPI?.replace('/chat', '/recommendation-letter/new')
      if (url)
        fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify(jsonObject),
          redirect: 'follow',
        })
          .then((response) => response.text())
          .then((result) => {
            const resultObj: { content: string } = JSON.parse(result)
            setResponse(resultObj.content)
            if (selectedBreadcrumbIndex) selectedBreadcrumbIndex.current = breadcrumbs.length

            setBreadcrumbs([...breadcrumbs, 'generate'])
            setIsSubmittingState(false)
          })
          .catch((error) => {
            console.error(error)
            setIsSubmittingState(false)
          })
    },
    [breadcrumbs, chatAPI, selectedBreadcrumbIndex, setBreadcrumbs, setResponse, session]
  )

  const checkboxRefs = useRef<(HTMLDivElement | null)[]>([])

  const submitHandler = async (data: RecLetterSchemaType): Promise<void> => {
    setIsSubmittingState(true)
    const { gender } = data
    const pronouns = gender === 'male' ? 'He, him, his' : gender === 'female' ? 'She, her, hers' : 'They, them, theirs'

    const jsonToBeSent = {
      Student_Name: 'STUDENT_PLACEHOLDER',
      Student_Pronouns: [pronouns],
      Counselor_Name: 'COUNSELOR_NAME_PLACEHOLDER',
      Counselor_Title: 'COUNSELOR_TITLE_PLACEHOLDER',
      School_Name: 'SCHOOL_PLACEHOLDER',
      Class_Year: 'CLASS_YEAR_PLACEHOLDER',
      Format_style: [data.formattingHints],
      Tone_style: [data.toneHints],
      Length_style: [data.length],
      Date: new Date().toString(),
      Distinctive_Qualities: data.distinctive_qualities,
      Academic_History: data.academic_interests,
      Activities_Interests_Awards: data.activities_and_interests,
      Personal_History: data.personal_history_and_family_context,
      Summary: data.summary,
    }
    update({ counselorForm: JSON.stringify(data) })
    submitForm(jsonToBeSent)
  }

  const errorHandler = (newErrors: FieldErrors<RecLetterSchemaType>) => {
    setErrors(newErrors)
    const firstError = Object.keys(newErrors)[0]
    if (firstError) {
      const errorIndex = checkboxGroups.findIndex((group) => group.id === firstError)
      if (errorIndex !== -1) {
        checkboxRefs.current[errorIndex]?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  return (
    <div className="w-full flex flex-col h-full">
      <div className="overflow-y-auto h-full">
        <form id="rec_letter_form" className="size-full pb-10" onSubmit={handleSubmit(submitHandler, errorHandler)}>
          <div className="text-gray-900 mt-3 md:mt-6">
            <div className="w-full">
              <p className="text-lg font-bold mb-2.5 w-full ">{t('letterStyle')}</p>
              <div className="flex flex-col sm-custom:flex-row flex-wrap gap-x-3 w-full ">
                {checkboxGroups.map((group, index) => {
                  return (
                    <CheckboxGroup
                      key={group.id}
                      id={group.id}
                      index={index}
                      label={group.label}
                      components={group.components}
                      removeError={() =>
                        setErrors((prevState) => {
                          const newState = { ...prevState }

                          delete newState[group.id]

                          return newState
                        })
                      }
                      error={Object.keys(errors).find((el) => el === group.id) && t('fieldRequired')}
                      {...register(group.id)}
                      value={watch(group.id)}
                      ref={(el) => (checkboxRefs.current[index] = el)}
                    />
                  )
                })}
              </div>
            </div>
            {textQuestions.map((question) => (
              <TextQuestionComponent
                textQuestion={question}
                key={question.id}
                register={register}
                setValue={setValue}
              />
            ))}
            <Button type="submit" className="bg-brand-purple" disabled={isSubmittingState}>
              {isSubmittingState ? <Spinner className="mx-auto h-4 w-4 text-white" /> : <p>{t('generate')}</p>}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RecLetterState
