import { ChatHandler } from './chat.interface'

const ChatInput = (
  props: Pick<
    ChatHandler,
    | 'isLoading'
    | 'input'
    | 'onFileUpload'
    | 'onFileError'
    | 'handleSubmit'
    | 'handleInputChange'
    | 'messages'
    | 'setInput'
    | 'append'
    | 'placeholder'
  >
) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    props.handleSubmit(e)
  }

  return (
    <form className="px-4 pt-0 md:px-8" onSubmit={onSubmit}>
      <div className="rounded-2xl md:rounded-3xl px-1 md:px-2">
        <input
          type="text"
          className="text-gray-700 w-full p-2 md:p-4 border border-gray-300 rounded-2xl md:rounded-3xl
          focus:outline focus:border-gray-300 focus:border focus:shadow-none"
          placeholder={props.placeholder}
          value={props.input}
          onChange={props.handleInputChange}
        />
      </div>
    </form>
  )
}

export default ChatInput
